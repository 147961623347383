import { FormControl } from '@angular/forms';
import { Formule } from '../formules.interface';

export interface CompteBancaireForm {
  nomTitulaire: FormControl<string>;
  iban: FormControl<string>;
  bic: FormControl<string>;
}

export interface CompteBancaire {
  compteBancaireId: string;
  nomTitulaire: string;
  iban: string;
  bic: string;
}

export interface ComptesBancairesListe {
  comptesBancaires: CompteBancaire[];
}

export interface DialogDataInput {
  formule: Formule;
  type: TypeDialog;
}

export enum TypeDialog {
  reddition,
  acompte,
}

export interface DialogAcompteRedditionData {
  btnValide: string;
  btnAnnuler: string;
  message: string;
  type: string;
  MontantMaxAcompte: number;
  idClient: string;
}

export interface DialogReturn {
  valide: boolean;
  montantAcompte: number;
  idCompteBancaire: string;
}

export interface PreviewAcompte {
  montantTotalCommissionsHT: number;
  montantTotalCommissionsTTC: number;
  montantReverseAuClient: number;
  libelleRegleCalculCommissions: string;
}

export interface PreviewReddition {
  montantTotalCommissionsHT: number;
  montantTotalCommissionsTTC: number;
  montantReverseAuClient: number;
  acomptesInclus: PreviewAcompte[];
  libelleRegleCalculCommissions: string;
}

export interface AjoutCompteBancaire {
  idClient: string;
  nomTitulaire: string;
  iban: string;
  bic: string;
}
