import { HttpClient, HttpParams } from '@angular/common/http';
import { computed, inject, Injectable, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import moment from 'moment';
import { distinctUntilChanged, filter, map, of, startWith, switchMap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AlertService } from '../../../partage/components/alert/alert.service';
import { FactureService } from '../../facturation/facture.service';
import { Client, PageFilter, SortFilter } from '../global/global-reddition.interface';
import {
  AcompteCreation,
  AcompteStatut,
  AcompteSuccess,
  Formule,
  FormuleDataTable,
  FormuleFilter,
  RedditionCreation,
  RedditionStatut,
  RedditionSuccess,
} from './formules.interface';
import {
  OperationsCommerciales,
  RapprochementDataTable,
  RapprochementFilter,
} from './panel-formule-detail/panel-formule-detail-interface';

@Injectable()
export class FormulesService {
  private _httpClient = inject(HttpClient);
  private alertService = inject(AlertService);
  private factureService = inject(FactureService);

  formuleSelection = signal<Formule | undefined>(undefined);
  voirDetail = signal<boolean>(false);
  factureEnCours = signal<boolean>(false);
  urlClient = signal('');
  filterClient = signal('');
  clientSelection = signal<Client | undefined>(undefined);
  filterform = signal<FormuleFilter | undefined>(undefined);
  sortFilter = signal<SortFilter>({ orderingField: 'date', orderingDirection: 0 });
  pageFilter = signal<PageFilter>({ numeroPage: 0, nbrParPage: 25 });
  acompteACreer = signal<AcompteCreation | undefined>(undefined);
  acompteSuccess = signal<AcompteSuccess | undefined>(undefined);
  redditionACreer = signal<RedditionCreation | undefined>(undefined);
  redditionSuccess = signal<RedditionSuccess | undefined>(undefined);
  acompteStatut = signal<AcompteStatut | undefined>(undefined);
  redditionStatut = signal<RedditionStatut | undefined>(undefined);

  private clients = toSignal<Client[]>(
    this._httpClient.get<Client[]>(`${environment.backendUrl}redditions/liste-clients`).pipe(startWith([] as Client[])),
  );

  filteredClient = computed(() => {
    const clients = this.clients();
    const filter = this.filterClient();
    if (clients) {
      return clients.filter(c => c.libelleClient.toLowerCase().includes(filter));
    }
    return [] as Client[];
  });

  private clientPreFilter = toSignal(
    toObservable(this.clients).pipe(
      filter(() => this.urlClient() != ''),
      map(cli => cli?.find(c => c.codeClient === this.urlClient())),
      filter(Boolean),
      map(client => {
        this.clientSelection.set(client);
        this.urlClient.set('');
      }),
    ),
  );

  private completeFilterForm = computed(() => ({
    filter: this.filterform(),
    page: this.pageFilter(),
    sort: this.sortFilter(),
  }));

  formules = toSignal(
    toObservable(this.completeFilterForm).pipe(
      filter(filter => filter.filter != undefined),
      switchMap(filter => {
        let queryparams = new HttpParams();
        queryparams = queryparams.appendAll({
          pageIndex: filter.page!.numeroPage,
          pageSize: filter.page!.nbrParPage,
          orderingField: filter.sort!.orderingField,
          orderingDirection: filter.sort!.orderingDirection,
          codeClients: filter.filter!.codeClients,
          rechercheFormule: filter.filter!.rechercheFormule,
          aRedditionnerUniquement: filter.filter!.aRedditionnerUniquement,
          estArchive: filter.filter!.estArchive,
        });
        if (moment(filter.filter!.filtreDateDebut).isValid()) {
          queryparams = queryparams.appendAll({
            filtreDateDebut: moment(filter.filter!.filtreDateDebut).format('YYYY-MM-DD'),
          });
        }
        if (moment(filter.filter!.filtreDateFin).isValid()) {
          queryparams = queryparams.appendAll({
            filtreDateFin: moment(filter.filter!.filtreDateFin).format('YYYY-MM-DD'),
          });
        }
        const href = `${environment.backendUrl}redditions/evenements`;
        return this._httpClient.get<FormuleDataTable>(href, {
          params: queryparams,
        });
      }),
    ),
    { initialValue: {} as FormuleDataTable },
  );

  reddition = computed(() => this.recupererOpertationCommerciale()?.reddition);
  chargerRapprochement = signal<boolean>(false);
  rapprochementFitler = signal<RapprochementFilter | undefined>(undefined);
  listeRapprochement = signal<RapprochementDataTable | undefined>(undefined);
  idHistoriqueAcompte = signal<string>('');
  idHistoriqueReddition = signal<string>('');
  idDetailATelecharger = signal('');
  nomFichierDetail = signal('');
  listeAcompte = computed(() => this.recupererOpertationCommerciale()?.acomptes);
  listeRedditionsAnnulees = computed(() => this.recupererOpertationCommerciale()?.redditionsAnnulees);
  totalCommande = computed(() => this.listeRapprochement()?.totalCount);

  private recupererOpertationCommerciale = toSignal(
    toObservable(this.formuleSelection).pipe(
      switchMap(evt => {
        if (evt) {
          this.chargerRapprochement.set(false);
          this.idHistoriqueAcompte.set('');
          this.idHistoriqueReddition.set('');
          this.listeRapprochement.set(undefined);

          const href = `${environment.backendUrl}redditions/formules/${evt!.id}/operations-commerciales`;
          return this._httpClient.get<OperationsCommerciales>(href);
        } else {
          return of({} as OperationsCommerciales);
        }
      }),
    ),
  );

  private recupRapproBancaire = computed(() => ({
    filter: this.rapprochementFitler(),
    formule: this.formuleSelection(),
    charger: this.chargerRapprochement(),
  }));

  private recupererRapprochementBancaire = toSignal(
    toObservable(this.recupRapproBancaire).pipe(
      filter(x => x.charger && x.formule != undefined && x.filter != undefined),
      distinctUntilChanged(
        (prev, curr) =>
          prev.formule?.id === curr.formule?.id && JSON.stringify(prev.filter) === JSON.stringify(curr.filter),
      ),
      switchMap(recup => {
        let queryparams = new HttpParams();
        queryparams = queryparams.appendAll({
          pageIndex: recup.filter!.numeroPage,
          pageSize: recup.filter!.nbrParPage,

          filtreStatutRapprochement: recup.filter!.statut,
        });
        if (recup.filter!.numeroCmd) {
          queryparams = queryparams.appendAll({
            filtreIdCommandeAparte: recup.filter!.numeroCmd,
          });
        }
        const href = `${environment.backendUrl}resumes-commandes/par-evenement/${recup.formule!.id}`;
        return this._httpClient
          .get<RapprochementDataTable>(href, {
            params: queryparams,
          })
          .pipe(
            map(result => {
              this.listeRapprochement.set(result);
            }),
          );
      }),
    ),
  );
}
